<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-hover v-slot="{ hover }">
        <v-btn
          class="lib-btn"
          v-show="currentProject"
          width="80"
          :color="hover ? (inProject ? 'error' : 'success') : inProject ? 'grey' : '#F26522'"
          depressed
          small
          @click="toggle"
          v-on="on"
        >
          <span>{{inProject ? 'Remove' : 'Add'}}</span>
        </v-btn>
      </v-hover>
    </template>
    <span>{{inProject ? 'Remove' : 'Add'}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    lib: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentProject() {
      const { Project } = this.$FeathersVuex.api;
      return Project.findInStore({ query: { uuid: this.$store.getters.currentProject } }).data[0] || null;
    },
    inProject() {
      return this.currentProject?.libraries?.some((lib) => lib.name === this.lib.name);
    },
  },
  methods: {
    toggle() {
      if (!this.currentProject) return;
      if (this.inProject) {
        this.$set(this.currentProject, 'libraries',
          this.currentProject.libraries.filter((lib) => lib.name !== this.lib.name));
      } else {
        this.currentProject.libraries ??= [];
        this.currentProject.libraries
          .push({ name: this.lib.name, version: 'latest', deps: this.lib.dependencies?.map(({ name }) => name) });
      }
      this.currentProject.save();
    },
  },
};
</script>

<style lang="scss">

  .lib-btn {
    color: white !important;
    border: 1px solid #F26522 !important;
    width: 80px !important;

    > span {
      color: white !important;
    }
  }

</style>
